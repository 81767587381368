<template>
  <v-list-item
      link
      :title="$t('menus.modules')"
      :to="{ name: 'Modules' }"
      color="white"
  >
    <v-list-item-icon class="d-flex justify-center">
      <planete-icon color="white">modules</planete-icon>
    </v-list-item-icon>
    <v-list-item-content link>
      <v-list-item-title class="white--text">
        {{ $t("menus.modules") }}
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: "MenuModules"
}
</script>

<style scoped>

</style>